function initWebsiteSearch() {
    const $input = $('.overlay-search .js-search-input');
    //const $buttonExplore = $('.overlay-search .js-search-form-submit');
    const $buttonOpenOverlay = $('[data-overlay-open="overlay-search"]');
    const $searchWrapper = $('.js-search-results');
    const $searchListingContainer = $('.overlay-search .js-listing-container');
    const $searchHeadContainer = $('.overlay-search .js-search-head-results');

    // Ajoute une class à la balise form pour annuler le curseur qui clignotte
    $input.focusin(function () {
        $(this).parents('form:first').addClass('focused');
    });

    $buttonOpenOverlay.on('click', function () {
        setTimeout(function () {
            $input.focus();
            $('.overlay-search form').addClass('focused');
        }, 1000);
    });

    setTimeout(function () {
        // init de algolia
        thuriaSearch.init(
            algolia.application_id,
            algolia.search_api_key,
            JSON.parse(algolia.indexes),
            $input.get(0),
            $searchWrapper.get(0),
            $searchListingContainer.get(0),
            null,
            searchCallback,
            $searchHeadContainer.get(0)
        );
    }, 1000);
}

function searchCallback() {
    const resultsContainer = document.querySelector('.js-listing-container')
    if(resultsContainer.classList.contains('hidden')) {
        resultsContainer.classList.remove('hidden')
    }
}


if ($('.overlay-search').length > 0) {
    if (typeof algolia === "undefined") {
        setTimeout(function () {
            initWebsiteSearch();
        }, 1000);
    }
}





