// Menu des ancres synchronisé avec la position du scroll de la page
class ThAnchors {
    constructor(rootNode) {
        this.selectors = {
            anchorSection: '.js-anchor-section',
            anchorLink: '.js-anchor-link',
        };

        // Marge pour positionner les sections sous la barre de navigation
        this.scrollOffset = parseInt(getComputedStyle(document.documentElement).getPropertyValue('--header-height'));
        if (!this.scrollOffset) this.scrollOffset = 80;
        const strateInfo = document.querySelector('.strate-info');
        if (strateInfo) this.scrollOffset += strateInfo.offsetHeight;

        this.anchorLinks = document.querySelectorAll(this.selectors.anchorLink);
        this.anchorSections = document.querySelectorAll(this.selectors.anchorSection);
        this.anchorSectionsVisible = [];        // Stocke les sections actuellement visibles dans le viewport

        // Système des ancres complétement désactivé en mobile
        if (document.body.clientWidth > thConfig.mobileBreakpoint) this.init();
    }


    init() {
        // Click dans la nav et scroll jusqu'à une section
        this.anchorLinks.forEach(link => {
            link.addEventListener('click', this.onLinkClick.bind(this));
        });
        // Activation des liens de la nav au scroll de la page
        this.initObserver();
    }

    // Scroll smooth jusqu'à une section
    onLinkClick(e) {
        e.preventDefault();
        const link = e.target;
        const currentId = link.getAttribute('href');
        const currentSection = document.querySelector(currentId);

        if (currentSection) {
            // Enregistre la position courante du scroll avant le focus pour empêcher le défilement sur la section de sacader
            const currentScollX = window.scrollX;
            const currentScollY = window.scrollY;
            currentSection.focus();
            window.scrollTo(currentScollX, currentScollY);      // repart de la position d'origine du scroll au moment du click

            scrollToTarget(currentSection, -this.scrollOffset);
            // scrollToTarget(currentSection, -this.scrollOffset, 'auto', null, this.onScrollComplete.bind(this));      // si besoin, callback une fois le scroll terminé
        }
    }

    // Scroll jusqu'à la section terminé
    onScrollComplete() {
        console.log('onScrollComplete');
    }


    // Détection de la section courante lors du défilement de la page
    initObserver() {
        if ('IntersectionObserver' in window) {
            this.observer = new IntersectionObserver(this.obCallback.bind(this), {
                rootMargin: '-120px 0px -120px 0px',
            });
            this.anchorSections.forEach(section => {
                section.setAttribute("tabindex", "-1");
                this.observer.observe(section);
            });
        }
    }

    // Callback de l'intersection observer
    obCallback(entries) {
        // entrée ou sortie de la section dans le viewport -> active le lien correspondant dans le menu des ancres
        entries.forEach(entry => {
            const entryId = entry.target.id;

            // Entrée -> Ajoute la section au tableau des sections actuellement visibles
            if (entry.isIntersecting) {
                this.anchorSectionsVisible.push(entry.target);
                this.updateCurrentSection();

                // Sortie -> Supprime la section du tableau des sections actuellement visibles
            } else {
                //console.log('•>out', entryId);
                if (this.anchorSectionsVisible.length) {
                    this.anchorSectionsVisible = this.anchorSectionsVisible.filter(x => x !== entry.target);
                    this.disableCurrentSection(entry.target);

                    if (this.anchorSectionsVisible.length) {
                        this.updateCurrentSection();
                    }
                }
            }
        });
    }


    // Met à jour la section courante en gérant les cas où deux sections sont visibles en même temps dans le viewport
    updateCurrentSection() {
        // tri les sections par position Y : de la plus haute à la plus basse
        // la section la plus haute dans la page a toujours la priorité sur les autres
        if (this.anchorSectionsVisible.length > 1) {
            this.anchorSectionsVisible.sort(function (a, b) {
                const aYpos = a.getBoundingClientRect().top + window.scrollY;
                const bYpos = b.getBoundingClientRect().top + window.scrollY;
                return aYpos - bYpos;
            });
        }

        this.anchorSectionsVisible.forEach((section, index) => {
            if (index === 0) {
                this.enableCurrentSection(section);     // 1ere section visible activée
            } else {
                this.disableCurrentSection(section);
            }
        });
    }

    // Active la section courante et le lien correspondant dans le menu
    enableCurrentSection(section) {
        section.classList.add('is-active');

        const currentLink = document.querySelector(`a[href="#${section.id}"]`);
        if (currentLink) {
            currentLink.classList.add('is-active');
            currentLink.setAttribute('aria-current', true);

            // Scroll de la nav pour avoir le lien visible
            currentLink.parentNode.scrollIntoView({behavior: 'auto', block: 'nearest', inline: 'center'});
        }
    }

    // Désactive la section courante et le lien correspondant dans le menu
    disableCurrentSection(section) {
        section.classList.remove('is-active');

        const currentLink = document.querySelector(`a[href="#${section.id}"]`);
        if (currentLink) {
            currentLink.classList.remove('is-active');
            currentLink.removeAttribute('aria-current');
        }
    }
}
