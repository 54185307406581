// Menu des ancres - gestion du défilement des liens
class NavAnchors {
    constructor(rootNode) {
        this.container = rootNode;
        this.navArrows = this.container.querySelector('.nav-arrows');
        this.btnPrev = this.container.querySelector('.btn-arrow-ancre.--prev');
        this.btnNext = this.container.querySelector('.btn-arrow-ancre.--next');
        this.list = this.container.querySelector('.nav-anchors__list');

        this.scrollStep = 150;

        if (this.list) {
            this.initArrows();
            this.initObserver();

            // Affiche / Masque les flèches de scroll si nécessaire
            this.list.addEventListener('scroll', this.updateArrowsState.bind(this));
            this.updateArrowsState();
        }
    }


    // Défilement de la liste des ancres en overflow avec les flèches
    initArrows() {
        this.btnPrev.addEventListener('click', (e) => {
            this.list.scrollBy({left: -this.scrollStep, behavior: "smooth"});
        });
        this.btnNext.addEventListener('click', (e) => {
            this.list.scrollBy({left: this.scrollStep, behavior: "smooth"});
        });
    }


    // Détecte si la largeur de la nav change et si on doit afficher ou non les flèches de scroll
    initObserver() {
        if ('ResizeObserver' in window) {
            const observer = new ResizeObserver((entries) => {
                entries.forEach(entry => {
                    if (isOverflowing(this.list)) {
                        this.showNavArrows();
                    } else {
                        this.hideNavArrows();
                    }
                });
            });
            observer.observe(this.list);
        }
    }


    // Affiche / Masque les flèches de scroll en fonction de la position du scroll
    updateArrowsState(e) {
        const target = this.list;

        if (target.scrollLeft <= 0) {
            this.btnPrev.classList.add('is-hidden');
        } else if (target.clientWidth + target.scrollLeft >= target.scrollWidth) {
            this.btnNext.classList.add('is-hidden');
        } else {
            if (this.btnPrev.classList.contains('is-hidden')) this.btnPrev.classList.remove('is-hidden');
            if (this.btnNext.classList.contains('is-hidden')) this.btnNext.classList.remove('is-hidden');
        }
    }


    // Affiche / Masque les flèches de scroll si nécessaire
    showNavArrows() {
        if (!this.navArrows.classList.contains('is-active')) {
            this.navArrows.classList.add('is-active');
        }
    }

    hideNavArrows() {
        if (this.navArrows.classList.contains('is-active')) {
            this.navArrows.classList.remove('is-active');
        }
    }
}


