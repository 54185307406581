(function () {
    class BlocSliderThematique {
        constructor(rootNode) {
            this.bloc = rootNode;

            // Init de la liste des tabs
            this.tablistNode = this.bloc.querySelectorAll('[role=tablist]');
            this.btnSwitch = this.bloc.querySelector('.js-switch-thematique');

            // Pas de gestion des tabs si un seul onglet / slider
            if (this.tablistNode && this.btnSwitch) this.init();
        }


        init() {
            this.tabs = Array.from(this.bloc.querySelectorAll('[role=tab]'));
            this.tabpanels = [];

            this.tabs.forEach(tab => {
                const tabpanel = document.getElementById(tab.getAttribute('aria-controls'));
                tab.tabIndex = -1;
                tab.setAttribute('aria-selected', 'false');
                this.tabpanels.push(tabpanel);

                tab.addEventListener('click', this.onTabClick.bind(this));
            });
            this.firstTab = this.tabs[0];

            this.btnSwitch.addEventListener('click', this.onSwitchClick.bind(this));

            this.currentId = 0;
            this.lastId = null;
            this.currentImage = null;
            this.lastImage = null;
            this.currentThematique = 'winter';

            // Active la 1ere tab
            this.setSelectedTab(this.firstTab, false);
        }


        onTabClick(event) {
            this.setSelectedTab(event.currentTarget);
        }


        // Active une tab
        setSelectedTab(currentTab, setFocus=true) {
            // tab déja actif
            if (this.currentId === Number(currentTab.dataset.tabId)) {
                return;
            }

            this.lastId = Number(this.currentId);
            this.currentId = Number(currentTab.dataset.tabId);

            this.tabs.forEach(tab => {
                const tabpanel = document.getElementById(tab.getAttribute('aria-controls'));

                // Active la tab courante
                if (currentTab === tab) {
                    tab.setAttribute('aria-selected', 'true');
                    tab.removeAttribute('tabindex');

                    tabpanel.removeAttribute('hidden');
                    tabpanel.classList.remove('is-hidden');
                    if (setFocus) {
                        tab.focus();
                    }

                // Désactive les tabs précédents
                } else {
                    tab.setAttribute('aria-selected', 'false');
                    tab.tabIndex = -1;

                    tabpanel.setAttribute('hidden', '');
                    tabpanel.classList.add('is-hidden');
                }
            });

            this.updateImage(currentTab);
        }


        // Transition des images
        updateImage(currentTab) {
            // direction de la transition d'entrée de la nouvelle image
            const dir = (this.currentId > this.lastId) ? 'right' : 'left';

            this.lastImage = this.currentImage;
            this.currentImage = document.getElementById(currentTab.dataset.imageThematique);

            if (this.lastImage) {
                this.lastImage.classList.remove('is-active');

                this.lastImage.classList.remove('trans-in-left');
                this.lastImage.classList.remove('trans-in-right');
                this.lastImage.classList.add('trans-out');
            }

            if(this.currentImage) {
                this.currentImage.classList.add('is-active');
                this.currentImage.classList.add('trans-in-' + dir);
            }

           // console.log('dir', dir, this.currentId, '('+this.lastId+')');
        }



        // Changement de thématique / saison au click sur le switch
        onSwitchClick() {
            if(this.currentThematique === 'winter') {
                this.currentThematique = 'summer';
                this.bloc.classList.remove('is-winter');
                this.bloc.classList.add('is-summer');

                // active la 1ere entrée de 'summer'
                const currentTab = document.querySelector('.col-content__theme.--summer .tab-thematique__btn');
                if (currentTab) this.setSelectedTab(currentTab);
            } else {
                this.currentThematique = 'winter';
                this.bloc.classList.remove('is-summer');
                this.bloc.classList.add('is-winter');

                // active la 1ere entrée de 'winter'
                const currentTab = document.querySelector('.col-content__theme.--winter .tab-thematique__btn');
                if (currentTab) this.setSelectedTab(currentTab);
            }
        }
    }


    // Init des blocs
    const blocs = document.querySelectorAll('.js-slider-thematique');
    blocs.forEach(function (bloc) {
        new BlocSliderThematique(bloc);
    });

})();
