if (isIE()) {
    document.body.classList.add("ie");
}

if (isNoHover()) {
    document.body.classList.add("no-hover");
}


// Fix le calcul des vh sur iOS et Chrome Android (css var --vh injectée en JS)
new calculateVheight();

// affichage des overlays possible que a partie de 2s
setTimeout(() => {
    document.body.classList.add("overlay-load");
}, 1000);

// Lazyload
var lazyLoadInstance = new LazyLoad({
    //elements_selector: ".lazy"
});

// Lancement du script Librairie Zoombox
$('.zoombox').zoombox();

/* Detect the scroll of the page down */
document.addEventListener('scroll', (e) => {
    const st = document.documentElement.scrollTop;

    if (st > 50) {
        document.body.classList.add("is-scrolled");
    } else {
        document.body.classList.remove("is-scrolled");
    }
});


/* Detect the scroll of the page up */
let lastscrolltop = 0;
let lastIsDirTop = 0;
document.addEventListener('scroll', (e) => {
    const st = document.documentElement.scrollTop;

    if (st < lastscrolltop && lastIsDirTop == 0) {
        lastIsDirTop = 1;
        document.body.classList.add("scrolldir-top");
    }
    if (st > lastscrolltop && lastIsDirTop == 1) {
        lastIsDirTop = 0;
        document.body.classList.remove("scrolldir-top");
    }
    lastscrolltop = st;
});


document.querySelectorAll('.js-player-video').forEach(el => new ThVideo(el));


// Initialisation de la classe
document.querySelectorAll('.js-accordion-group').forEach(el => new ThAccordionGroup(el)  );


// Initialisation de la nav des ancres
if (document.querySelector('.js-nav-anchors')) {
    new ThAnchors(document.querySelector('.js-nav-anchors'));
    new NavAnchors(document.querySelector('.js-nav-anchors'));
}
