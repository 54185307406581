(function () {

    // Init des sliders
    const sliders = document.querySelectorAll('.js-slider-brochures');
    sliders.forEach(slider => {
        const splide = new Splide(slider, {
            arrows: false,
            mediaQuery: 'min',
            breakpoints: {
                600: {
                    destroy: true,
                },
            }
        }).mount();
    });

})();